import React from 'react';
import Chart from 'react-apexcharts';
import { Row, Col, Typography, Table, Empty, Collapse, Modal, Space, Form, Input, Rate, Image } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

const { Title, Link } = Typography;
const { Panel } = Collapse;

const Global = props => {

    let { filteredInfo } = props.state;
    filteredInfo = filteredInfo || {};

    const [form2] = Form.useForm();

    form2.setFieldsValue({ search: props.state.search });

    var obj = {
        get news () {
            if ( props.state.news.length > 0 ) {
                let search = props.state.search.toLowerCase();
                return props.state.news.filter( function( item ) {
                    return(
                        item['org_name'].toLowerCase().indexOf( search ) > -1
                    );
                } );
            } else {
                return props.state.news;
            }
        }
    }

    const columns = [
        {
            title: 'Организация',
            dataIndex: 'org_name',
            key: 'org_name',
            width: 100,
            render: (text) => text,
            filters: obj.news.map(function(item){return {text:item.org_name,value:item.org_name}}).map(JSON.stringify).reverse().filter(function (e, i, a) {return a.indexOf(e, i+1) === -1;}).reverse().map(JSON.parse),
            filteredValue: filteredInfo.org_name || null,
            onFilter: (value, record) => record.org_name.includes(value),
            sorter: (a, b) => a.org_name.localeCompare(b.org_name),
        },{
            title: 'Важность',
            dataIndex: 'importance',
            key: 'importance',
            width: 100,
            render: (text) => text,
            filters: obj.news.map(function(item){return {text:item.importance,value:item.importance}}).map(JSON.stringify).reverse().filter(function (e, i, a) {return a.indexOf(e, i+1) === -1;}).reverse().map(JSON.parse),
            filteredValue: filteredInfo.importance || null,
            onFilter: (value, record) => record.importance.includes(value),
            sorter: (a, b) => a.importance.localeCompare(b.importance),
        },{
            title: 'Дата',
            dataIndex: 'date',
            key: 'date',
            render: (text) => props.lasttime(text,'Неизвестно когда','last'),
            width: 100,
            sorter: (a, b) => a.date.localeCompare(b.date),
        }
    ];

    function opennew(newid) {
        props.panel('new_view');
        props.api({ 'method':'med_news_get', 'newid':`${newid}` },false);
    }

    return (
        <React.Fragment>
            <Modal key="m12" mask={false} width={900} zIndex="900" title="Просмотр новости" visible={props.state.panel.indexOf( 'new_view' ) != -1 } onOk={() => props.panel('new_view')} onCancel={() => props.panel('new_view')} footer={null}>
                {!props.state.moreload ? (
                    <React.Fragment>
                        {0 in props.state.new_get &&
                            <>
                                <Col span={24} style={{padding:20}} >
                                    <p className="theme-des"><span>Организация: </span><span>{props.state.new_get[0]['org_name']}</span></p>
                                    <p className="theme-des"><span>Дата публикации: </span><span>{props.lasttime(props.state.new_get[0]['date'],'Неизвестно когда','last')}</span></p>
                                    <p className="theme-des"><span>Важность: </span><span className="nums_rate">{[1,2,3,4,5,6,7,8,9,10].map(num => <span className={`num_rate ${Number(props.state.new_get[0]['importance']) === num ? ' num_rate_active' : ''}`}>{num}</span>)}</span></p>
                                    <p className="theme-des"><span>Новость:</span></p>
                                    <pre className="theme-text">
                                        {props.state.new_get[0]['text']}
                                    </pre>
                                    <br />
                                    {
                                        (1 in props.state.new_get && Object.keys(props.state.new_get[1]).length) ?
                                            <React.Fragment>
                                                <p className="theme-des"><span>Изображения:</span></p>
                                                <div className='img_gal'>
                                                    {
                                                        1 in props.state.new_get &&
                                                            props.state.new_get[1].map( image => 
                                                                <Image width={150} className='img_mini' src={`https://clinic.rus2.ru/km/medic/telegram_uploads/${image['name']}`} />
                                                            )
                                                    }
                                                </div>
                                            </React.Fragment>
                                        :
                                            null
                                    }
                                </Col>
                            </>
                        }
                    </React.Fragment>
                ) : (
                    <div className="fcenter">
                        <Space direction="vertical">
                            <div className="spincenter"><div className="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>
                            <div className="fcenter">
                                Получение информации
                            </div>
                        </Space>
                    </div>
                )}
            </Modal>
            <Row style={{flexDirection:'column'}} className="column900 wowload">
                <Col key="k1_clients" span={24} style={{marginBottom:10}} >
                    <Space wrap>
                        <Form style={{maxWidth:'210px'}} name="form" form={form2} >
                            <Form.Item key="search" name="search"><Input placeholder="Поиск" suffix={<SearchOutlined />} onChange={ props.onChange } /></Form.Item>
                        </Form>
                    </Space>
                </Col>
                <Col span={24} style={{marginTop:20}} className="shadowscreen" >
                    <Table 
                        onRow={(record, rowIndex) => {
                            return {
                                onDoubleClick: event => { opennew(record.id) }
                            };
                        }} 
                        className="table-padding"
                        onChange={props.htc}
                        pagination={{ pageSize: 30 }}
                        locale={{ emptyText: <Empty description="Список пуст" />}} dataSource={obj.news} scroll={{ x: 500 }} rowKey="table2" columns={columns}>
                    </Table>
                </Col>
            </Row>
        </React.Fragment>
    )
};

export default Global;